<template>
  <div class="worship-visit-record" v-infinite-scroll="getWorships">
    <div class="list-null"  v-if="worships.length<1">
      <div>暂无祭拜记录</div>
    </div>
    <div class="list" v-for="(worship,index) of worships" :key="index" v-if="worships.length > 0">
      <img v-if="worship.worship_user" :src="worship.worship_user.head_img_url">
      <img v-else src="../../../assets/images/default-avatar.png">
      <div class="list-rig">
        <div class="rig-top"><span class="name">{{worship.worship_user.name}}</span><span class="time">{{ worship.worship_time}}</span>
        </div>
        <div class="record">献上 <span> {{worship.num}} {{ worship.worship_goods.unit}} {{worship.worship_goods.name}} </span> 表达了对 {{worship.hall.name}}的思念
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'worship-visit-record',
    data() {
      return {
        hallId: '48e32dc432204326a86f8ce00285dab2',
        worships: [],
        paging: {
          page: 1,
          pageSize: 10,
          last: false,
        }
      }
    },
    created() {

    },
    methods: {
      getWorships() {
        let _this = this;
        if(_this.paging.last){
          return;
        }
        let _param = {
          hallId: _this.hallId,
          page: _this.paging.page,
          rows:_this.paging.pageSize
        };
        _this.$api.worship.getWorships(_param).then(res => {
          if (res.data) {
            for (let i=0 ; i<res.data.length ; i++){
              _this.worships.push(res.data[i])
            }
            _this.paging.last = (_this.paging.page >= res.paging.pages);
            if (!res.paging.last){
              _this.paging.page++
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .worship-visit-record {
    height: 927px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 0 20px;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;

    .list-null{
      @include w-flex;
      @include align-items;
      @include justify-content;
      color: #5e5e5e;
      font-size: 15px;
      align-items: center;
      height: 563px;
      flex-flow: column;
      justify-content: center;

      img{
        height: 262px;
        margin-bottom: 50px;
        margin-right: 40px;
      }
    }
    .list {
      padding: 20px 0;
      border-bottom: 1px solid #EDEDED;
       @include w-flex;

      .list-rig {
        width: 100%;
        margin-left: 18px;

        .record {
          font-size: 16px;

          span {
            color: #33CBF7;
          }
        }

        .rig-top {
          @include align-items;
          justify-content: space-between;
          @include w-flex;

          .name {
            font-size: 20px;
            color: #000000;
          }

          .time {
            font-size: 16px;
            color: #676767;
          }
        }
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    &::-webkit-scrollbar {
     display: none; /* Chrome Safari */
    }
  }
</style>
